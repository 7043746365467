import { useState } from "react";
import { NavBar } from "../../components/navbar";
import { Buscador } from "../../components/UTILS/Buscador";






export function ReporteCuadraturaCapital() {
    const [empresa, setEmpresa] = useState("")
    const [agencia, setAgencia] = useState("")
    const [fechaInicio, setFechaInicio] = useState("")
    const [fechaFinal, setFechaFinal] = useState("")
    const [nombreEmpresa, setNombreEmpresa] = useState("")

    const handlerBuscar = async () => {
        console.log(empresa, agencia, fechaInicio, fechaFinal)
    }
    return <>
        <NavBar/>
        <Buscador
         setempresa = {(e) => {setEmpresa(e)}}
         setagencia = {(e) => {setAgencia(e)}}
         setfechainicio = {(e) => {setFechaInicio(e)}}
         setfechafinal = {(e) => {setFechaFinal(e)}}
         setNombreEmpresa = {(e) => {setNombreEmpresa(e)}}
         titulo = {"Cuadratura de capital"}
         empresa = {empresa}
         setBuscar = {handlerBuscar}
         mostrarTodas = {true}
         />
    </>
}